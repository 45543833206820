import { DOCUMENT, NgIf, AsyncPipe, SlicePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationCancel, NavigationEnd, NavigationError, Router, Scroll, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
    ButtonComponent,
    ListComponent,
    LoaderComponent,
    LogoComponent,
    SfxCommonsLocalizerService
} from 'sfx-commons';
import { environment } from '../environments/environment';
import {
    LANGUAGE_FILE_NAME_SUFFIX,
    MOMENT_DATE_FORMATS_PER_LANGUAGE,
    MOMENT_DATE_MONTH_FORMATS_PER_LANGUAGE,
    SafeAny
} from './constants';
import { DiagnosticLog } from './constants/types';
import { DomUpdateSchedulerService } from './core/services/dom-update-scheduler.service';
import { LocationUtils } from './core/utils/location.utils';
import { ObjectUtils } from './core/utils/object.utils';
import { RouterUtils } from './core/utils/router.utils';
import { DiagnosticsService } from './diagnostics/services/diagnostics.service';
import { MadTranslateService } from './mad-translate/services/mad-translate.service';
import { Userpilot } from 'userpilot';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, RouterOutlet, AsyncPipe, SlicePipe, LogoComponent, LoaderComponent, ListComponent, ButtonComponent, TranslateModule]
})
export class AppComponent implements OnInit, AfterViewChecked {
    public areDiagnosticsEnabled = false;
    public diagnosticLogs: Observable<Array<DiagnosticLog>>;
    public isAppLoading = true;
    public isModernBrowser = true;

    private readonly diagnosticsQueryParamName = 'sfxdiag';

    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly cdr: ChangeDetectorRef,
                private readonly diagnosticsSvc: DiagnosticsService,
                @Inject(DOCUMENT) private document: Document,
                private readonly domUpdateSchedulerSvc: DomUpdateSchedulerService,
                private readonly madTranslateSvc: MadTranslateService,
                private readonly router: Router,
                private readonly sfxCommonsLocalizerSvc: SfxCommonsLocalizerService,
                private readonly translate: TranslateService) {
        const { queryParams } = LocationUtils.parseQueryString() || {};
        this.areDiagnosticsEnabled = this.diagnosticsSvc.tryActivateService(queryParams && queryParams[this.diagnosticsQueryParamName]);
        this.diagnosticLogs = this.diagnosticsSvc.logs;

        this.translate.onLangChange
            .pipe(untilDestroyed(this))
            .subscribe(({ lang }) => {
                this.document.documentElement.lang = lang;
            });

        this.activatedRoute.queryParams
            .pipe(untilDestroyed(this))
            .subscribe((params) => {
                if (params[this.diagnosticsQueryParamName]) {
                    this.areDiagnosticsEnabled = this.diagnosticsSvc.tryActivateService(params[this.diagnosticsQueryParamName]);
                }
            });

        window.onpopstate = async () => {
            const oldLanguage = this.router.url.split('/')[1];
            const newLanguage = window.location.pathname.split('/')[1];
            if (oldLanguage !== newLanguage) {
                await this.madTranslateSvc.setSelectedLanguage(ObjectUtils.getKeyForValue(LANGUAGE_FILE_NAME_SUFFIX, newLanguage));
            }
        };

        let routePaths = [];
        let routeComponents = {};
        this.router.events
            .pipe(untilDestroyed(this))
            .subscribe((event): void => {
                switch (true) {
                    case event instanceof ActivationStart:
                        // eslint-disable-next-line no-case-declarations
                        const eventDup = event as SafeAny;
                        if (event && eventDup.snapshot && eventDup.snapshot.routeConfig && eventDup.snapshot.routeConfig.component) {
                            const lastActivatedComponentName = eventDup.snapshot.routeConfig.component.name;
                            const lastActivatedRoute = RouterUtils.getFullPathFromSnapshot(eventDup.snapshot);
                            routePaths.push(lastActivatedRoute);
                            routeComponents[lastActivatedRoute] = lastActivatedComponentName;
                        }
                        break;
                    case event instanceof NavigationEnd:
                    case event instanceof NavigationError:
                    case event instanceof NavigationCancel:
                    case event instanceof Scroll && event.routerEvent instanceof NavigationEnd:
                        // eslint-disable-next-line no-case-declarations
                        const newActiveComponentIndex = routePaths.findIndex((item) => item === (event as SafeAny).urlAfterRedirects);
                        if (newActiveComponentIndex > -1) {
                            const toRemove = routePaths.splice(newActiveComponentIndex + 1);
                            toRemove.map(toRemoveKey => delete routeComponents[toRemoveKey]);
                        } else {
                            if ((event as SafeAny).urlAfterRedirects === '/' || routePaths.length) {
                                const toRemoveIndex = routePaths.length - 1 - routePaths.reverse().findIndex(item => item === '/');
                                if (toRemoveIndex + 1 === routePaths.length) {
                                    routePaths = [];
                                    routeComponents = {};
                                } else {
                                    const toRemove = routePaths.splice(toRemoveIndex);
                                    toRemove.map(toRemoveKey => delete routeComponents[toRemoveKey]);
                                }
                            }
                        }

                        this.domUpdateSchedulerSvc.updatePathAndComponents(routePaths, routeComponents);
                        this.domUpdateSchedulerSvc.updateActiveComponent();
                        if (this.isAppLoading) {
                            this.isAppLoading = false;
                        }
                        if (!!environment.features.userPilot.isEnabled) {
                            Userpilot.reload();
                        }
                        break;
                }
            });


        this.isModernBrowser = this.detectIfModernBrowser();
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    public async ngOnInit(): Promise<void> {
        await this.madTranslateSvc.initialize();
        this.sfxCommonsLocalizerSvc.setDateFormatsPerLanguage(MOMENT_DATE_FORMATS_PER_LANGUAGE);
        this.sfxCommonsLocalizerSvc.setDateMonthFormatsPerLanguage(MOMENT_DATE_MONTH_FORMATS_PER_LANGUAGE);
    }

    public async disabledDiagnostics(): Promise<void> {
        this.diagnosticsSvc.disable();
        this.areDiagnosticsEnabled = this.diagnosticsSvc.tryActivateService(null);
        const queryParams = { ...this.activatedRoute.snapshot.queryParams };
        if (queryParams[this.diagnosticsQueryParamName]) {
            delete queryParams[this.diagnosticsQueryParamName];
            await this.router.navigate([], { queryParams: queryParams });
        }
    }

    private detectIfModernBrowser(): boolean {
        const ua = window.navigator.userAgent;

        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return false;
        }

        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            return false;
        }

        return true;
    }
}
