import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpTranslateLoaderFactory } from './mad-translate/mad-translate-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { MadMissingTranslationHandler } from './mad-translate/mad-missing-translations-handler';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { provideNzConfig } from 'ng-zorro-antd/core/config';
import { AppInitializerService } from './core/services/app-initializer.service';
import { AuthenticationService } from './projects/services/authentication.service';
import { SfxErrorHandler } from './core/sfx-error-handler/sfx-error-handler';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, UrlSerializer, withRouterConfig } from '@angular/router';
import { CleanUrlSerializer } from './core/utils/clean-url-serializer';
import { provideSfxDiInterceptors } from './core/utils/http-interceptors.utils';
import { provideSfxCommons } from 'sfx-commons';
import { provideMarkdown } from 'ngx-markdown';

const configureAsyncRoutesAndAuth = (appInitSvc: AppInitializerService, authSvc: AuthenticationService) => {
    return async () => {
        try {
            await appInitSvc.init();
            await authSvc.init();
        } catch (ex) {
            console.error(ex);
        }
    };
};

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpTranslateLoaderFactory,
                    deps: [HttpClient]
                },
                missingTranslationHandler: {
                    provide: MissingTranslationHandler,
                    useClass: MadMissingTranslationHandler
                },
                useDefaultLang: false
            }),
            OAuthModule.forRoot()
        ),
        AppInitializerService,
        OAuthService,
        { provide: ErrorHandler, useClass: SfxErrorHandler },
        { provide: UrlSerializer, useClass: CleanUrlSerializer },
        {
            provide: APP_INITIALIZER,
            useFactory: configureAsyncRoutesAndAuth,
            deps: [AppInitializerService, AuthenticationService],
            multi: true
        },
        provideNzConfig({ notification: { nzPlacement: 'bottomRight' } }),
        provideSfxCommons(),
        provideMarkdown(),
        provideAnimations(),
        provideRouter([], withRouterConfig({ paramsInheritanceStrategy: 'always' })),
        provideHttpClient(withJsonpSupport(), withInterceptorsFromDi()),
        provideSfxDiInterceptors()
    ]
};
